import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { api } from "../utils/axios-instance";

function FileUploadComponent() {
  const [files, setFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleFilelisting = async (page) => {
    try {
      const getFiles = await api.get(`/file/files?p=${page}`);

      console.log("Files:", getFiles.data);
      console.log("Response Headers:", getFiles); // Log response headers
      setFiles(getFiles.data);
      setTotalPages(Math.ceil(getFiles.headers.count / 25));
    } catch (error) {
      console.error("Error at file listing test:", error.message);
    }
  };

  useEffect(() => {
    handleFilelisting(currentPage); // Fetch files when component mounts or currentPage changes
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update currentPage when pagination changes
  };

  return (
    <>
      <div>
        <button onClick={() => handleFilelisting(1)}>get files</button>{" "}
        {/* Submit button for file update */}
        {files.length && (
          <Stack spacing={2}>
            <Pagination
              count={totalPages} // Assuming there are 10 pages
              page={currentPage}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          </Stack>
        )}
      </div>
    </>
  );
}

export default FileUploadComponent;
